"use client";

import React from "react";
import { EmptyBlock } from "../domain/EmptyBlock";
import { createSafeContext } from "@openchatai/hoose/utils";
import { components } from "@openchatai/sdk";
type User = components["schemas"]["User"];
const [UserdataSafeProvider, useUserData] = createSafeContext<User>("");
function UserDataProvider({
  children,
  data
}: {
  children: React.ReactNode;
  data: {
    user: User;
  };
}) {
  if (!data) {
    return <div className="flex-center size-full">
        <EmptyBlock>
          <p>
            We couldn't load the application. we have been notified of this
            error and will fix it as soon as possible.
          </p>
        </EmptyBlock>
      </div>;
  }
  return <UserdataSafeProvider value={data.user} data-sentry-element="UserdataSafeProvider" data-sentry-component="UserDataProvider" data-sentry-source-file="UserDataProvider.tsx">{children}</UserdataSafeProvider>;
}
export { useUserData, UserDataProvider };