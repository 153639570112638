"use client";

import { Socket, io } from "socket.io-client";
import React, { ReactNode, useCallback, useEffect, useMemo, useReducer } from "react";
import { produce } from "immer";
import { NEXT_PUBLIC_HEMIDAL_URL } from "@/data/base-url";
import { createSafeContext } from "@openchatai/hoose/utils";
import { useUserData } from "@/components/payments/UserDataProvider";
import { useOrg } from "../_data-hooks/useOrganization";
import { useMount } from "react-use";
const socketUrl = NEXT_PUBLIC_HEMIDAL_URL?.replace("backend", "") ?? "" ?? "";
type SocketState = {
  state: "stale" | "connected" | "retrying" | "disconnected" | "error";
  reason: string | null;
  reconnectAttempts: number | null;
};
type SocketContextData = {
  __socket: Socket | null;
  state: SocketState;
};
type ActionType = {
  type: "CONNECTED_AND_JOINED";
} | {
  type: "RECONNECT_ATTEMPT";
  payload: number;
} | {
  type: "CONNECTED";
} | {
  type: "DISCONNECTED";
  payload: string;
};
function socketReducer(state: SocketState, action: ActionType) {
  return produce(state, draft => {
    switch (action.type) {
      case "RECONNECT_ATTEMPT":
        draft.state = "retrying";
        draft.reconnectAttempts = action.payload;
        break;
      case "CONNECTED":
        draft.state = "connected";
        break;
      case "DISCONNECTED":
        draft.state = "disconnected";
        draft.reason = action.payload;
        break;
    }
  });
}
const [SocketSafeProvider, useSocket] = createSafeContext<SocketContextData>("");
function SocketProvider({
  children
}: {
  children: ReactNode;
}) {
  const {
    id
  } = useUserData();
  const {
    id: orgId
  } = useOrg();
  const [state, dispatch] = useReducer(socketReducer, {
    state: "stale",
    reason: null,
    reconnectAttempts: null
  });
  const [socket, setSocket] = React.useState<Socket | null>(null);
  useMount(() => {
    setSocket(io(socketUrl, {
      transports: ["websocket"],
      forceNew: true
    }));
  });
  const handleConnect = useCallback(() => {
    socket?.emit("join:dashboard", {
      orgId,
      userId: id
    });
    dispatch({
      type: "CONNECTED"
    });
  }, [id, orgId, socket]);
  const handleDisconnect = useCallback((reason: string) => {
    dispatch({
      type: "DISCONNECTED",
      payload: reason
    });
  }, []);
  const handleReconnectAttempt = useCallback((attempt: number) => {
    dispatch({
      type: "RECONNECT_ATTEMPT",
      payload: attempt
    });
  }, []);
  useEffect(() => {
    socket?.on("joined:dashboard", (data: {
      orgId: string;
      userId: string;
    }) => {
      if (data.userId === id.toString()) {
        dispatch({
          type: "CONNECTED_AND_JOINED"
        });
      }
    });
    return () => {
      socket?.off("joined:dashboard");
    };
  }, [id, socket]);
  useEffect(() => {
    // Fired upon a successful connection.
    socket?.on("connect", handleConnect);
    // Fired upon a disconnection.
    socket?.on("disconnect", handleDisconnect);
    // Fired upon an attempt to reconnect.
    socket?.on("reconnect_attempt", handleReconnectAttempt);
    return () => {
      socket?.off("connect", handleConnect);
      socket?.off("disconnect", handleDisconnect);
      socket?.off("reconnect_attempt", handleReconnectAttempt);
    };
  }, [socket, handleConnect, handleDisconnect, handleReconnectAttempt]);
  return <SocketSafeProvider value={{
    __socket: socket,
    state
  }} data-sentry-element="SocketSafeProvider" data-sentry-component="SocketProvider" data-sentry-source-file="SocketProvider.tsx">
      {children}
    </SocketSafeProvider>;
}
export { useSocket, SocketProvider };