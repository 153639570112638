import(/* webpackMode: "eager", webpackExports: ["AppLink"] */ "/app/apps/dashboard/app/_components/AppLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/app/apps/dashboard/app/_data-providers/SocketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentModalOrNone"] */ "/app/apps/dashboard/app/[locale]/(authenticated)/(home)/_parts/PaymentModalOrNone.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserDataProvider"] */ "/app/apps/dashboard/components/payments/UserDataProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+a_qzeygbfiqhfvsdwuptq34obxby/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+a_qzeygbfiqhfvsdwuptq34obxby/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_supports-color@9.4.0__@opentelemetry+api@1.9.0_react-dom@18.3_klodhkv5ypqvk252cyydceezaq/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/packages/hoose/dist/ui.js");
