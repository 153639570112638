import { useOrg } from "../_data-hooks/useOrganization";

export function useLinkHirarcy() {
  const { id } = useOrg();
  const orgIndex = `/org/${id}`;
  return {
    auth: {
      login: "/auth",
      signout: "/signout",
    },
    org: {
      reports: {
        index: `${orgIndex}/reports`,
      },
      inbox: {
        index: `${orgIndex}`,
        session: (sessionId: string) => `${orgIndex}/s/${sessionId}`,
        sessionPlay: (sessionId: string) =>
          `${orgIndex}/s/${sessionId}/?play=true`,
        indexV2: `${orgIndex}/inbox`,
        sessionV2: (sessionId: string) => `${orgIndex}/inbox/s/${sessionId}`,
      },
      settings: {
        index: `${orgIndex}/settings`,
        appearance: `${orgIndex}/settings/appearance`,
        accessToken: `${orgIndex}/settings/access`,
        context: `${orgIndex}/settings/context`,
        aiAutopilot: `${orgIndex}/settings/autopilot`,
        integrations: `${orgIndex}/settings/integrations`,
        domains: `${orgIndex}/settings/domains`,
        officeHours: `${orgIndex}/settings/office-hours`,
        teammates: `${orgIndex}/settings/teammates`,
        general: `${orgIndex}/settings/`,
      },
      channels: {
        index: `${orgIndex}/channels/`,
        configure: {
          web: `${orgIndex}/channels/configure/widget`,
          phone: `${orgIndex}/channels/configure/phone`,
          email: `${orgIndex}/channels/configure/mail`,
        },
        web: `${orgIndex}/channels/configure/widget`,
      },
      email: {
        index: `${orgIndex}/e`,
        new: `${orgIndex}/e/new`,
        newToOne: (to: string) => `${orgIndex}/e/new?to=${to}`,
        sequencing: {
          index: `${orgIndex}/e/seq`,
          new: `${orgIndex}/e/seq/new`,
          sequence: (seqId: string) => `${orgIndex}/e/seq/${seqId}`,
        },
      },
      contacts: {
        index: `${orgIndex}/contacts/`,
      },
      helpCenter: {
        index: `${orgIndex}/help-center/`,
        create: `${orgIndex}/help-center/create`,
        edit: (helpCenterId: string) =>
          `${orgIndex}/help-center/${helpCenterId}/edit`,
        helpCenter: (helpCenterId: string) =>
          `${orgIndex}/help-center/${helpCenterId}`,
        categories: {
          category: (helpCenterId: string, categoryId: string) =>
            `${orgIndex}/help-center/${helpCenterId}/?category-id=${categoryId}`,
        },
      },
      phone: {
        index: `${orgIndex}/channels/configure/phone`,
        history: `${orgIndex}/channels/configure/phone/call-history`,
      },
      actions: {
        index: `${orgIndex}/actions/`,
        newAction: `${orgIndex}/actions/new`,
        action: (actionId: string) => `${orgIndex}/actions/${actionId}`,
      },
      knowledge: {
        index: `${orgIndex}/knowledge-base/`,
      },
      training: {
        index: `${orgIndex}/training/`,
      },
    },
  };
}
