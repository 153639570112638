"use client";

import { Link, useRouter } from "@/lib/router-events";
import { Button } from "@openchatai/hoose/ui";
import React, { ComponentPropsWithRef, forwardRef } from "react";
import { useLinkHirarcy } from "../_hooks/useLinkHirarcy";
interface AppLinkProps extends Omit<React.ComponentProps<"a">, "href"> {
  href: string | ((links: ReturnType<typeof useLinkHirarcy>) => string);
}
export const AppLink = forwardRef<HTMLAnchorElement, AppLinkProps>(function Linky({
  href,
  ...rest
}, ref) {
  const links = useLinkHirarcy();
  if (typeof href === "function") {
    href = href(links);
  }
  return <Link {...rest} ref={ref} href={href} />;
});
export const BackLink = forwardRef<HTMLButtonElement, ComponentPropsWithRef<typeof Button>>(function Backkk({
  onClick,
  ...rest
}, ref) {
  const router = useRouter();
  return <Button {...rest} ref={ref} onClick={ev => {
    ev.preventDefault();
    router.back();
    onClick?.(ev);
  }} />;
});